<template>
<div>
    <SearchProduct />
</div>
</template>

<script>
import SearchProduct from '../components/dashboard/SearchProduct.vue'
export default {
    components: {
        SearchProduct
    }
}
</script>

<style>
